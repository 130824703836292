import { Tag, TagGroup, TagGroupProps } from "@gfg/ui-v2/components/tag";
import { CopyIcon } from "@gfg/ui-v2/icons";
import { makeStyles } from "@gfg/ui-v2/theming";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useNotification } from "@gfg/ui-v2/components/notification";

const useStyles = makeStyles()(() => ({
  tag: {
    cursor: "pointer",
  },
}));

interface SkuButtonProps extends TagGroupProps {
  value: string;
}

export default function SkuButton({ value, className, ...rest }: SkuButtonProps) {
  const { classes, cx } = useStyles();
  const { addNotification } = useNotification();
  const { region } = useParams();

  const handleNavigate = useCallback(() => {
    window.open(`/${region}/product-catalog?q=${value}`, "_blank");
  }, [region, value]);

  const handleCopy = useCallback(async () => {
    await navigator.clipboard.writeText(value);

    addNotification("Successfully copied", "SKU was copied successfully", {
      color: "success",
      closable: true,
      timeout: 2000,
    });
  }, [addNotification]);

  return (
    <TagGroup size="small" className={cx(classes.tag, className)} {...rest}>
      <Tag onClick={handleNavigate} size="small" variant="outline" label={value} />
      <Tag icon={<CopyIcon />} onClick={handleCopy} />
    </TagGroup>
  );
}
