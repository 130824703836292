import React, { useState, useRef, useEffect, HTMLProps, useCallback } from "react";
import Tooltip from "@gfg/ui-v2/components/tooltip";
import { makeStyles } from "@gfg/ui-v2/theming";
import { CopyIcon } from "@gfg/ui-v2/icons";
import { IconButton } from "@gfg/ui-v2/components/button";
import { useNotification } from "@gfg/ui-v2/components/notification";

interface RowItemProps extends HTMLProps<HTMLDivElement> {
  name: string;
  value: string;
}

const useStyles = makeStyles()(({ colors, spacing, palette, typography }) => ({
  truncated: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    cursor: "pointer",
  },
  name: {
    color: colors.primary,
    fontWeight: typography.fontWeights.bold,
    marginRight: spacing("md"),
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100%",
  },
  row: {
    gap: spacing(15),
    borderBottom: `1px solid ${palette.tertiary[200]}`,
    paddingTop: spacing(7),
    paddingBottom: spacing(7),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const RowItem = ({ name, value, ...props }: RowItemProps) => {
  const textRef = useRef(null);
  const { classes } = useStyles();
  const { addNotification } = useNotification();

  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const checkTruncation = () => {
      const element = textRef.current;
      if (element) {
        setIsTruncated(element.scrollWidth > element.clientWidth);
      }
    };

    checkTruncation();

    window.addEventListener("resize", checkTruncation);
    return () => {
      window.removeEventListener("resize", checkTruncation);
    };
  }, []);

  const handleCopy = useCallback(async () => {
    await navigator.clipboard.writeText(value);

    addNotification("Successfully copied", `${name} was copied successfully`, {
      color: "success",
      closable: true,
      timeout: 2000,
    });
  }, [name]);

  return (
    <div className={classes.row}>
      <div ref={textRef} className={classes.item} key={name} {...props}>
        <span className={classes.name}>{name}</span>{" "}
        {isTruncated ? (
          <Tooltip triggerElement={<span className={classes.truncated}>{value}</span>}>{value}</Tooltip>
        ) : (
          <span>{value}</span>
        )}
      </div>
      <IconButton onClick={handleCopy} size="small" variant="faded" icon={CopyIcon} round />
    </div>
  );
};

export default RowItem;
