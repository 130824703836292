import { useCallback, useEffect, useState } from "react";

import { useReactiveVar } from "@apollo/client";
import { FeedPreviewType } from "@coral/typings";
import debounce from "lodash/debounce";
import { useSearchParams } from "react-router-dom";
import pagination from "../../../../common/constants/pagination";
import reactiveVariables from "../../../../common/graphql/global-reactive-variables";
import { useCustomFeedPreviewLazyQuery } from "~/generated";
import useCachedState from "~/common/hooks/use-cached-state";

interface UsePreviewProps {
  feedId: string;
  feedPreviewType: FeedPreviewType;
  showIdField?: boolean;
  showSkuField?: boolean;
  limit?: number;
  offset?: number;
}
const USER_ID_POSTFIX = `preview-user-id`;
const SKU_KEY_POSTFIX = `preview-sku`;

export default function usePreview({ feedId, feedPreviewType, showIdField, showSkuField }: UsePreviewProps) {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(+pagination.LIMITS[pagination.LIMITS.length - 1]);
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);
  const [searchParams, setSearchParams] = useSearchParams();

  const SKU_KEY = `${selectedEnvironmentProjectId}-${SKU_KEY_POSTFIX}`;
  const USER_ID_KEY = `${selectedEnvironmentProjectId}-${USER_ID_POSTFIX}`;
  const [sku = "", setSku] = useCachedState(SKU_KEY, showSkuField, searchParams.get("sku"));
  const [uuid = "", setUuid] = useCachedState(USER_ID_KEY, showIdField, searchParams.get("uuid"));

  const [getFeedPreview, { data, loading, previousData }] = useCustomFeedPreviewLazyQuery({
    fetchPolicy: "network-only",
    variables: {
      params: {
        feedID: feedId,
        feedPreviewType,
        projectId: selectedEnvironmentProjectId,
        sku: sku || undefined,
        userID: uuid || undefined,
        limit,
        offset,
      },
    },
  });

  const handleChangeSKU = useCallback((value: string) => {
    setSku(value);
    setSearchParams(prev => {
      prev.set("sku", value?.trim() || "");
      return prev;
    });
  }, []);

  const handleChangeUUID = useCallback((value: string) => {
    setUuid(value);
    setSearchParams(prev => {
      prev.set("uuid", value?.trim() || "");
      return prev;
    });
  }, []);

  const previewData = (data ?? previousData)?.customFeedPreview;

  const debouncedGetFeedPreview = useCallback(
    debounce(async params => {
      await getFeedPreview({
        variables: { params },
      });
    }, 200),
    [getFeedPreview],
  );

  useEffect(() => {
    const params = {
      feedID: feedId,
      feedPreviewType,
      projectId: selectedEnvironmentProjectId,
      sku: sku || undefined,
      userID: uuid || undefined,
      limit,
      offset,
    };

    debouncedGetFeedPreview(params);

    return () => {
      debouncedGetFeedPreview.cancel();
    };
  }, [offset, sku, uuid, limit, feedId, feedPreviewType, selectedEnvironmentProjectId]);

  return {
    limit,
    loading,
    offset,
    previewData,
    sku,
    uuid,
    getFeedPreview,
    setLimit,
    setOffset,
    setSku: handleChangeSKU,
    setUuid: handleChangeUUID,
  };
}
