import { PreviewItem, Product } from "@coral/typings";

const makePreviewRows = (preview: PreviewItem): { name: string; value: string }[] => [
  ...(preview.ctl_algorithm ? [{ name: "Strategy", value: preview.ctl_algorithm }] : []),
  {
    name: "Name",
    value: preview.title,
  },
  {
    name: "Brand",
    value: preview.brand?.name,
  },
  {
    name: "Price",
    value: `${preview.price.previous.toString()} - ${preview.price.current.toString()} ${preview.price.currency}`,
  },
  {
    name: "Stock",
    value: preview.stock_count.toString(),
  },
  {
    name: "Categories",
    value: preview.categories?.map(item => item.name).join(", ") || "No categories",
  },
  {
    name: "Color",
    value: preview.colors?.join(", ") || "No colors",
  },
  {
    name: "Gender",
    value: preview.gender?.join(", ") || "No gender",
  },
];

export const makeSeedRows = (product: Product): { name: string; value: string }[] => [
  {
    name: "Name",
    value: product.title,
  },
  {
    name: "Brand",
    value: product.brand?.name,
  },
  {
    name: "Price",
    value: `${product.price.previous.toString()} - ${product.price.current.toString()} ${product.price.currency}`,
  },
  {
    name: "Stock",
    value: product.stockCount.toString(),
  },
  {
    name: "Categories",
    value: product.categories.map(item => item?.name)?.join(", ") || "No categories",
  },
  {
    name: "Color",
    value: product.stockAttributes.find(item => item.name === "color")?.value?.join(", ") || "No colors",
  },
  {
    name: "Gender",
    value: product.stockAttributes.find(item => item.name === "gender")?.value?.join(", ") || "No gender",
  },
];

export default makePreviewRows;
