import { useState } from "react";

export default function useCachedState(localStorageKey: string, enabled: boolean, initialState = "") {
  return useState(() => {
    if (enabled && !initialState) {
      return localStorage.getItem(localStorageKey);
    }

    return initialState;
  });
}
