import { Controller, FieldValues, useForm } from "react-hook-form";
import { InputField, SearchInput } from "@gfg/ui-v2/components/input";
import { useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { makeStyles } from "@gfg/ui-v2/theming";
import synonymsValidationSchema from "./synonyms-validation-schema";
import CreateButton from "../../../common/components/create-button";
import useScreenWidth from "../../../common/hooks/use-screen-width";
import useCreateSynonymsSet from "../../hooks/use-create-synonyms-set";
import { convertStringToSynonyms } from "../../utils";
import InProgressDialogNotification from "../../../common/components/dialogs/in-progress-dialog-notification";
import useEventTracking from "../../../common/hooks/use-tracking-event";

const useStyles = makeStyles()(({ spacing, colors, breakpoints }) => ({
  form: {
    display: "flex",
    padding: spacing(30, 0),
    justifyContent: "space-between",
    alignItems: "center",

    [breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },
  synonymsInput: {
    "& small": {
      color: colors.error,
      textAlign: "left",
      fontSize: 12,
      height: 0,
    },
  },
  search: {
    width: "100%",
  },
}));

interface SynonymsHeaderProps {
  searchValue: string;
  setSearchValue: (val: string) => void;
}

export default function SynonymsHeader({ searchValue, setSearchValue }: SynonymsHeaderProps) {
  const { classes } = useStyles();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(synonymsValidationSchema), defaultValues: { synonyms: "" } });

  const sendAddSynonymsSetAnalyticsEvent = useEventTracking({ eventName: "synonym-add-new-set" });

  const { isDesktop } = useScreenWidth();
  const { createSynonymsSet, inProgress: createInProgress } = useCreateSynonymsSet();

  const onSubmit = useCallback(async (formData: FieldValues) => {
    const synonyms = convertStringToSynonyms(formData.synonyms);
    if (await createSynonymsSet(synonyms)) {
      reset();
    }
  }, []);

  const handleChangeSearchValue = useCallback((val: string) => setSearchValue(val), []);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Controller
          name="synonyms"
          control={control}
          render={({ field }) => (
            <InputField
              {...field}
              label="Enter synonyms comma-separated, e.g. jeans, pants."
              hint={errors.synonyms?.message?.toString()}
              placeholder="jeans, pants, trousers"
              className={classes.synonymsInput}
              length={isDesktop ? 56 : undefined}
            />
          )}
        />
        <CreateButton
          onClick={() => {
            sendAddSynonymsSetAnalyticsEvent();
          }}
          text="Add Synonyms"
        />
        <InProgressDialogNotification open={createInProgress} closable={false} text="Creating synonyms" />
      </form>
      <SearchInput
        className={classes.search}
        placeholder="Search Synonym..."
        value={searchValue}
        onChange={handleChangeSearchValue}
      />
    </div>
  );
}
