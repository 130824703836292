import React, { useEffect, useRef, useState } from "react";
import { InputField } from "@gfg/ui-v2/components/input";
import { PreviewItem, Product } from "@coral/typings";
import LinearProgress from "@gfg/ui-v2/components/linear-progress";
import { makeStyles } from "@gfg/ui-v2/theming";
import Tooltip from "@gfg/ui-v2/components/tooltip";
import TableFooter from "../../../../../common/components/table/table-footer";
import Spacing from "../../../../../common/components/spacing";
import FlexWrapContainer from "../../../../../common/components/containers/flex-wrap-container";
import FeedPreviewItem from "../preview-item";
import makePreviewRows, { makeSeedRows } from "~/feed/components/feed-preview/utils/make-preview-rows";
import makeImage from "~/common/components/recommendations/utils/make-image";

const useStyles = makeStyles()(({ spacing, colors, palette, size, typography }) => ({
  inputContainer: {
    display: "inline-grid",
    gridTemplateColumns: "1fr 1fr",
    gap: spacing(80),
    width: "100%",
  },
  itemsHeading: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontWeight: typography.fontWeights.bold,
    color: colors.text,
    fontSize: typography.h5.fontSize,
    display: "inline-flex",
    width: `calc(100% - ${size(300)})`,
  },
  configSummaryContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    width: "100%",
    background: palette.gray[100],
    color: colors.darkgray,
    padding: spacing(20),
    borderRadius: spacing(10),
  },
  configSummaryHeadline: {
    fontWeight: "bolder",
  },
  resultHint: {
    marginTop: spacing(-15),
    color: colors.gray,
  },
  chosen: {
    marginBottom: spacing("md"),
  },
  itemsHeader: {
    display: "flex",
    justifyContent: "flex-start",
    gap: spacing("md"),
    marginBottom: spacing("sm"),
  },
  previewsHeader: {
    fontWeight: typography.fontWeights.bold,
    color: colors.text,
    fontSize: typography.h5.fontSize,
    display: "inline-flex",
    width: size(300),
  },
  truncated: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    cursor: "pointer",
  },
}));

interface PreviewBodyProps {
  sku: string;
  onSKUChange?(query: string): void;

  uuid: string;
  onUuidChange?(uuid: string): void;

  items: PreviewItem[];

  chosenStrategies: string[];
  total: number;
  loading: boolean;
  showIdField?: boolean;
  showSkuField?: boolean;
  seedProduct?: Product;

  offset: number;
  limit: number;
  onLimitChange(limit: number): void;
  onOffsetChange(offset: number): void;
}

const ItemsHeading = ({ children }: { children: React.ReactNode; className: string }) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const { cx, classes } = useStyles();
  const textRef = useRef(null);

  useEffect(() => {
    const checkTruncation = () => {
      const element = textRef.current;
      if (element) {
        setIsTruncated(element.scrollWidth > element.clientWidth);
      }
    };

    checkTruncation();

    window.addEventListener("resize", checkTruncation);
    return () => {
      window.removeEventListener("resize", checkTruncation);
    };
  }, []);

  return (
    <div className={classes.itemsHeading} ref={textRef}>
      {isTruncated ? (
        <Tooltip triggerElement={<span className={cx(classes.truncated)}>{children}</span>}>{children}</Tooltip>
      ) : (
        <span>{children}</span>
      )}
    </div>
  );
};

export default function PreviewBody({
  sku,
  onUuidChange,
  uuid,
  total,
  onSKUChange,
  items,
  limit,
  offset,
  loading,
  seedProduct,
  showIdField,
  showSkuField,
  onLimitChange,
  onOffsetChange,
  chosenStrategies,
}: PreviewBodyProps) {
  const { classes, cx } = useStyles();

  return (
    <>
      <div>
        {chosenStrategies?.length ? (
          <div className={classes.chosen}>
            <strong>Chosen strategies:</strong> {chosenStrategies.join(", ")}
          </div>
        ) : null}
      </div>
      <div className={classes.inputContainer}>
        {showSkuField && onSKUChange && <InputField value={sku} onChange={onSKUChange} block placeholder="Enter SKU" />}
        {showIdField && onUuidChange && (
          <InputField value={uuid} onChange={onUuidChange} block placeholder="Enter UUID" />
        )}
      </div>
      <p className={classes.resultHint}>
        {total ? (
          <>
            Found <b>{total}</b> result items.
          </>
        ) : (
          <>No results found</>
        )}
      </p>
      <Spacing top={10} />
      {loading && <LinearProgress indeterminate />}

      <Spacing top={20} />

      <div>
        {!loading && seedProduct && (
          <div className={classes.itemsHeader}>
            <span className={classes.previewsHeader}>Source item</span>
            <ItemsHeading className={classes.previewsHeader}>{chosenStrategies.join(", ")}</ItemsHeading>
          </div>
        )}

        <FlexWrapContainer>
          {seedProduct && (
            <FeedPreviewItem
              isSeed
              id={seedProduct.id}
              values={makeSeedRows(seedProduct)}
              imagePath={seedProduct?.imagePath}
            />
          )}
          {items?.map(item => (
            <FeedPreviewItem
              key={item.id}
              id={item.id}
              values={makePreviewRows(item)}
              imagePath={makeImage(item?.images)}
            />
          ))}
        </FlexWrapContainer>
      </div>

      <Spacing top={20} />

      <TableFooter
        limit={limit.toString()}
        offset={offset}
        total={total}
        onLimitChange={onLimitChange}
        onOffsetChange={onOffsetChange}
      />
    </>
  );
}
