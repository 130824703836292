import { useParams } from "react-router-dom";
import LinearProgress from "@gfg/ui-v2/components/linear-progress";
import { CampaignPreview } from "@coral/typings";
import SearchPreviewBody from "search-config/pages/search-preview/components/search-preview-body";
import PageContainer from "../../../common/components/containers/page-container";
import ContentContainer from "../../../common/components/containers/content-container";
import usePreview from "./hooks/use-preview";
import { isSuccessfulResponse } from "../edit-campaign";

export default function CampaignPreviewPage() {
  const { campaignId } = useParams();

  const { configData, previewData, query, uuid, setOffset, setLimit, offset, loading, limit } = usePreview({
    campaignId,
  });

  return (
    <PageContainer>
      <h4>Merchandising Preview</h4>

      <ContentContainer>
        {isSuccessfulResponse(configData) && (
          <SearchPreviewBody
            loading={loading}
            configId={campaignId}
            versionId={configData?.value}
            configName={configData?.name}
            isCampaign
            query={query}
            uuid={uuid}
            searchPreviewItems={previewData?.previews as CampaignPreview[]}
            limit={limit}
            offset={offset}
            onLimitChange={setLimit}
            onOffsetChange={setOffset}
            total={previewData?.total}
          />
        )}
        {!isSuccessfulResponse(configData) && loading && <LinearProgress indeterminate />}
      </ContentContainer>
    </PageContainer>
  );
}
