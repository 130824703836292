import { useCallback } from "react";
import AutocompleteMultiselect from "../../../inputs/autocomplete-multiselect";

interface Value {
  value: string;
  label?: string;
}

interface GetValuesResponse {
  data: Value[];
}

interface GetValuesProps {
  limit?: number;
  query?: string;
  attributeId: string;
}

export type GetValuesFunc = (values: GetValuesProps) => Promise<GetValuesResponse>;

export const defaultGetValuesFunc: FilterAutocompleteMultiselectProps["getValues"] = async ({ query }) => ({
  data: [{ value: query, label: query }],
});

export interface FilterAutocompleteMultiselectProps {
  value: string[] | string;
  attributeId?: string;
  label?: string;
  disabled?: boolean;

  invalidHint?: string;
  valid?: boolean;

  onChange(value: string[]): void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  getValues?: GetValuesFunc;
}

export default function AutocompleteMultiselectFilter({
  value,
  onChange,
  onBlur,
  disabled = false,
  getValues = defaultGetValuesFunc,
  attributeId,
  label,
  invalidHint,
  valid,
}: FilterAutocompleteMultiselectProps) {
  const getFilteredSuggestions = useCallback(
    async (term: string) => {
      const { data } = await getValues({ query: term, limit: 100, attributeId });

      const values = data?.map(({ value, label }) => ({
        id: value,
        value: label || value,
      }));

      return [{ items: values ?? [] }];
    },
    [getValues, attributeId],
  );

  return (
    <AutocompleteMultiselect
      placeholder="Select value"
      values={value}
      onSelectedValuesChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      autocompleteFn={getFilteredSuggestions}
      label={label}
      invalidHint={invalidHint}
      valid={valid}
    />
  );
}
