import { useCallback } from "react";
import { AutocompleteField } from "@gfg/ui-v2/components/autocomplete";
import { defaultGetValuesFunc, GetValuesFunc } from "./autocomplete-multiselect-filter";

export interface FilterAutocompleteProps {
  value: string;
  attributeId?: string;
  label?: string;
  disabled?: boolean;

  invalidHint?: string;
  valid?: boolean;

  onChange(value: string): void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  getValues?: GetValuesFunc;
}

export default function AutocompleteFilter({
  value,
  onChange,
  onBlur,
  disabled = false,
  getValues = defaultGetValuesFunc,
  attributeId,
  label,
  invalidHint,
  valid,
}: FilterAutocompleteProps) {
  const getFilteredSuggestions = useCallback(
    async (term: string) => {
      const { data } = await getValues({ query: term, limit: 100, attributeId });

      const values = data?.map(({ value, label }) => ({
        id: value,
        value: label || value,
      }));

      return [{ items: values ?? [] }];
    },
    [getValues, attributeId],
  );

  const handleSelectSuggestion = ({ id }: { id: string }) => {
    onChange(id);
  };

  return (
    <AutocompleteField
      placeholder="Select value"
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      disabled={disabled}
      autocompleteFn={getFilteredSuggestions}
      onSelectSuggestion={handleSelectSuggestion}
      label={label}
      invalidHint={invalidHint}
      autocompleteTimeout={100}
      valid={valid}
      block
    />
  );
}
