/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { InputField } from "@gfg/ui-v2/components/input";
import { Controller, useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { useCallback } from "react";
import SearchConfigAttributeWithRulesFilter from "search-config/components/search-config-form/filters/search-config-attribute-with-rules";
import { AttributeFilterWithRuleFormValue } from "common/components/form-filters/attribute-filters-with-rules";
import SectionLabel from "common/components/recommendations/section-label";
import SectionTitle from "common/components/recommendations/section-title";
import preventSubmitOnEnter from "common/utils/form/prevent-submit-on-enter";
import { SearchConfigType } from "@coral/typings";
import Radio, { RadioGroup } from "@gfg/ui-v2/components/radio";
import FormHeader from "./form-header";
import useStyles from "./use-styles";
import campaignValidationSchema from "./validation-schema";
import HardBoostingInput from "../hard-boosting-input";
import ErrorMessage from "~/common/components/error-message";
import CampaignTypeMap from "~/campaigns/constants/campaign-type";
import SearchConfigConditionalFilter from "~/search-config/components/search-config-form/filters/search-config-conditional-filter";
import { ConditionalFilterFormValue } from "~/common/components/form-filters/conditional-filters";

export interface FormData {
  campaignName: string;
  value: string;
  rankAttributes: AttributeFilterWithRuleFormValue[];
  boostedSkus: string[];
  type: SearchConfigType;
  conditionFilters: ConditionalFilterFormValue[];
}

export interface CampaignFormProps {
  onSubmit(formData: FormData): Promise<void>;
  onCancel(): void;
  onPreview?(): void;
  formTitle: string;
  defaultValues?: FormData;
  allowImmediateSubmit?: boolean;

  readOnly?: boolean;
}

const getValuePlaceholder = (type: SearchConfigType) => {
  switch (type) {
    case "Category":
      return "Type Category name";
    case "Search":
      return "Type Search term";
    case "Brand":
      return "Type Brand name";
    case "Campaign":
      return "Type Campaign ID";
    default:
      return "";
  }
};

export default function CampaignForm({
  onSubmit,
  onCancel,
  onPreview,
  formTitle,
  allowImmediateSubmit,
  defaultValues,
  readOnly = false,
}: CampaignFormProps) {
  const { classes, cx } = useStyles();
  const methods = useForm<FormData>({
    resolver: yupResolver(campaignValidationSchema),
    mode: "onChange",
    defaultValues: defaultValues ?? {
      campaignName: "",
      conditionFilters: [],
      value: "",
      rankAttributes: [],
      boostedSkus: [],
      type: "Campaign",
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    reset,
    watch,
  } = methods;

  const handleSubmitAndResetForm: SubmitHandler<FormData> = useCallback(
    async formData => {
      await onSubmit(formData);
      reset({}, { keepValues: true });
    },
    [onSubmit, reset],
  );

  const type = watch("type");

  const isSubmitDisabled = readOnly || (allowImmediateSubmit ? !isValid : !isDirty || !isValid);

  return (
    <FormProvider {...methods}>
      <form onKeyDown={preventSubmitOnEnter} onSubmit={handleSubmit(handleSubmitAndResetForm)}>
        <FormHeader title={formTitle} onCancel={onCancel} onPreview={onPreview} disabledSubmit={isSubmitDisabled} />
        <div className={classes.generalSettings}>
          <SectionTitle
            title="Merchandising rule"
            description="Fill this form to customize the ranking of the products on the page"
          />
          <div className={cx(classes.inputField, classes.textField, classes.verticallyMiddle)}>
            <SectionLabel
              title="Rule name*"
              description="Assign a rule name to identify the merchandising rule. This name will be displayed in the Datajet portal for identification purposes. It will not be visible or utilized anywhere else."
            />
            <Controller
              name="campaignName"
              control={control}
              render={({ field }) => (
                <InputField
                  {...field}
                  disabled={readOnly}
                  hint={errors.campaignName?.message}
                  placeholder="Rule name"
                  block
                />
              )}
            />
          </div>
          <div className={cx(classes.inputField, classes.verticallyMiddle)}>
            <SectionLabel
              title="Merchandising type"
              description="Select the page type where you want to merchandise the products"
            />
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field} className={classes.radioGroup} aria-orientation="vertical">
                  {Object.values(CampaignTypeMap).map((type: SearchConfigType) => (
                    <Radio key={type} color="primary" value={type}>
                      {CampaignTypeMap[type]}
                    </Radio>
                  ))}
                </RadioGroup>
              )}
            />
            <ErrorMessage message={errors.type?.message?.toString()} />
          </div>
          <div className={cx(classes.inputField, classes.textField, classes.verticallyMiddle)}>
            <SectionLabel
              title="Value *"
              description="Value refers to a search term, category name, brand name, or campaign ID, depending on the merchandising type."
            />
            <Controller
              name="value"
              control={control}
              render={({ field }) => (
                <InputField
                  {...field}
                  disabled={readOnly}
                  hint={errors.value?.message}
                  placeholder={getValuePlaceholder(type)}
                  block
                />
              )}
            />
          </div>
          <div>
            <SectionTitle
              title="Ranking Rules"
              description={
                <>
                  Add ranking rules to promote products on the catalog page (Search/Campaign/ Category). All the ranking
                  criteria will be used for ranking products on the catalog pages
                  <br />
                  <a target="_blank" href="/docs/campaign">
                    Read more about the Ranking
                  </a>
                </>
              }
            />
            <SearchConfigAttributeWithRulesFilter
              addAttributeButtonLabel="Add Soft Boosting"
              disabled={readOnly}
              fieldName="rankAttributes"
              type="Ranking"
              excludeAttributes={["sku"]}
            />
          </div>
          <div>
            <SectionTitle
              title="Boosted SKUs"
              description={
                <>
                  All the SKUs added here will be shown at the top of the catalog page (Search/Campaign/ Category), in
                  the same order they are added here
                </>
              }
            />
            <Controller
              name="boostedSkus"
              control={control}
              render={({ field }) => <HardBoostingInput onChange={field.onChange} skus={field.value} />}
            />
          </div>
          <div>
            <SectionTitle
              title="Filters"
              description={
                <>
                  Filters are the conditional rules to exclude or include certain products from the results. <br />
                  <a target="_blank" href="/docs/search-management/search-config#components-of-search-configurations">
                    Read more about the Filters
                  </a>
                </>
              }
            />
            <SearchConfigConditionalFilter fieldName="conditionFilters" disabled={readOnly} />
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
